.AddUser-body {
    height: 600px;
    max-height: 90%;
}

.AddUser-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 52px;
    background-color: white;
    z-index: 10000;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .AddUser-title {
        font-size: 16px;
        font-weight: 600;
        color: #1a1a1a;
        line-height: 22px;
    }
    .anticon-close {
        font-size: 16px;
    }
}

.AddUser-content {
    height: 100%;
    padding-top: 52px;
    overflow-y: scroll;
    .AddUser-section {
        &-title {
            padding: 8px 16px;
            background-color: #f8f8f8;
            > span {
                font-size: 12px;
                color: #3d3d3d;
                line-height: 16px;
            }
        }
        .adm-form-item-child-inner,
        .adm-form-item-feedback-error {
            text-align: right;
        }
        input,
        textarea {
            text-align: right;
        }
        .adm-form-item.adm-form-item-horizontal.adm-list-item {
            --prefix-width: 8em;
        }
        .adm-list-item-content-arrow,
        .MobileSelector-arrow-right {
            font-size: 14px;
            font-weight: 600;
        }
        .AddUser-form-item-placeholder {
            color: #ccc;
        }
    }
    .AddUser-submit {
        padding: 16px 16px 24px;
        .AddUser-submit-button {
            --border-color: #313653;
            --background-color: #313653;
        }
    }
}
