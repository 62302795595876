/*
 * File: app.less
 * Desc: 描述
 * File Created: 2020-07-26 18:27:37
 * Author: yezi
 * ------
 * Copyright 2020 - present, yezi
 */
@prefix: app;

.@{prefix} {
    &_layout {
        flex-direction: column;
        transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        &_content {
            padding: 0 16px;
            overflow: initial;
            flex: 1 1 0;
        }
        &_foot {
            text-align: center;
        }
    }
}

.ant-drawer > * {
    transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: none !important;
}

.ant-drawer-body {
    padding: 0 !important;
}
