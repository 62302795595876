.header {
    .tableTop {
        display: flex;
        justify-content: space-between;

        .updateTime {
            height: 32px;
            display: flex;
            align-items: flex-end;
            color: red;
        }
    }
}

.pagination {
    display: flex;
    justify-content: flex-end;
    margin: 10px !important;
}
