//美化webkit内核滚动条
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
::-webkit-scrollbar-thumb {
    background-color: #777;
}

@media screen and (max-width: 600px) {
    ::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
}
