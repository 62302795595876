.bonds_quotation_table {
    margin-top: 10px;

    .btn_area {
        .btn {
            width: 0;
            border: none;
            background-color: transparent;
            margin: 0;
            box-shadow: none;
            &[ant-click-animating-without-extra-node]:after {
                border: 0 none;
                opacity: 0;
                animation: none 0 ease 0 1 normal;
            }
        }
    }

    .selectionTable {
        :global(.ant-table-row) {
            cursor: pointer;
        }
    }
}
