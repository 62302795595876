.content {
    display: flex;
    justify-content: space-between;
    height: 100%;

    .email {
        width: 74%;
        height: fit-content;
    }
    .detail {
        width: 25%;
        background: white;
        padding: 10px;
        height: fit-content;
        max-height: 100%;
        overflow: auto;
    }
}
