.index {
    display: flex;
    flex-wrap: wrap;
    border-radius: 8px;
    padding: 5px 16px;
    background-color: white;

    .indexItem {
        width: 20%;
        padding: 15px 5px;

        &:nth-of-type(-n + 5) {
            border-bottom: 1px dashed #eaecef;
        }

        &:nth-of-type(5n) {
            border-right: none;

            div {
                border-right: none;
            }
        }

        div {
            border-right: 1px dashed #eaecef;

            &:first-of-type {
                font-size: 16x;
                color: #333333;
            }

            &:nth-of-type(2) {
                font-size: 14px;
                color: #aaaaaa;
            }
            &:nth-of-type(3) {
                font-size: 20px;
            }
        }

        .fluctuation {
            font-size: 14px;
        }
        .date {
            font-size: 14px;
            line-height: 22px;
            color: #aaa;
        }
    }
}

.rise {
    color: #ee0000;
}

.fall {
    color: #6dae43;
}
