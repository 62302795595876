.flexContainer {
    width: 100%;
    display: flex;
    align-items: center;
    .flexItem {
        margin: 8px 0;
        flex: 1;
        padding: 0 20px;
    }
    .flexItem4 {
        margin: 8px 0;
        flex: 4;
        padding: 0 20px;
    }
    .flexItem2 {
        margin: 8px 0;
        flex: 2;
        padding: 0 20px;
    }
    .flexItem3 {
        margin: 8px 0;
        flex: 3;
        padding: 0 20px;
    }

    :global(.ant-select-arrow) {
        right: 30px;
    }
    :global(.ant-input-number-input) {
        text-align: right;
    }
    :global(.ant-input-number-handler-wrap) {
        display: none !important;
    }
}
.header {
    background-color: #bdbdbd;
    height: 40px;
    text-align: center;
}
