.title {
    font-size: 18px;
    margin-top: 24px;
    margin-bottom: 16px;
    color: #333;
}

.pannel {
    background-color: white;
    border-radius: 8px;
    padding: 16px;
    color: #333;
    height: 100%;

    .titleArea {
        display: flex;
        justify-content: space-between;

        .pannelTitle {
            font-size: 16px;
            line-height: 26px;
        }
    }

    .time {
        font-size: 14px;
        line-height: 22px;
        color: #aaa;
    }

    .count {
        font-size: 28px;
        line-height: 40px;
    }

    .countUnit {
        font-size: 14px;
        line-height: 22px;
        margin-left: 4px;
    }

    .curreny {
        margin-left: 4px;
    }

    .amount {
        font-size: 16px;
        line-height: 26px;
        color: #aaa;
    }

    .content {
        font-size: 14px;
        line-height: 24px;
        color: #aaa;

        .rise {
            color: #ee0000;
            margin-left: 8px;
            margin-right: 16px;
        }

        .fall {
            color: #6dae43;
            margin-left: 8px;
            margin-right: 16px;
        }
    }
}
