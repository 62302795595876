.header {
    font-size: 18px;
    line-height: 26px;
    font-weight: bold;
}

.orders {
    width: 100%;

    .orderHeader {
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;

        .action {
            font-size: 18px;

            .actionBtn {
                min-width: 18px;
                margin-right: 10px;
                display: inline-block;
                cursor: pointer;
            }
        }
    }

    .transferArea {
        padding: 0 10px;
    }
}

.email {
    .emailContent {
        padding: 10px;
        display: flex;
        flex-wrap: wrap;

        .emailField {
            padding: 4px 6px 0px 0px;
            width: 100%;
            border-bottom: 1px solid #ccc;
            display: flex;
            align-items: center;
            min-height: 36px;
            white-space: pre-line;

            &:last-of-type {
                border-bottom: none;

                .fieldValue {
                    margin-left: 0;
                }
            }

            .fieldName {
                flex: 0 0 auto;
                font-size: 16px;
                font-weight: bold;
            }

            .fieldValue {
                margin-left: 10px;
                overflow-wrap: break-word;
            }

            .plusBtn {
                cursor: pointer;
                margin-left: 10px;
                font-size: 20px;
            }
        }
    }

    .btn {
        padding-left: 10px;
        margin: 10px 0 20px 0;
    }
}
