.container {
    display: flex;
    // min-height: calc(100% - 40px);
    .division {
        width: 1px;
        background-color: #000;
    }
}
.overdue {
    padding-left: 100px;
    padding-bottom: 90px;
}
