.modal-wrapper {
    &::before {
        display: none;
    }
    div[class='ant-modal'] {
        height: 100vh;
    }
    div[class='ant-modal-content'] {
        height: 100%;
    }
    div[class='ant-modal-body'] {
        height: calc(100vh - 120px);
        padding: 0;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 0;
        }
    }
    div[class='ant-collapse-content-box'] {
        padding: 0;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            height: 0;
        }
    }
    .modal-action-area {
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: white;
        .modal-actions {
            padding: 16px 0;
            display: flex;
            justify-content: center;
            column-gap: 64px;
        }
    }
    .form-item {
        margin-bottom: 0;
    }

    table,
    tr,
    td {
        border: 1px solid #d9d9d9;
    }
    table {
        width: 100%;
    }
    tr {
        height: 40px;
    }
    th {
        min-width: 128px;
        text-align: center;
    }
    td {
        min-width: 280px;
        padding: 8px 16px;
    }
}
