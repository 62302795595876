.container {
    padding: 16px 0;
    div[class='ant-collapse-content-box'] {
        padding: 0;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            height: 0;
        }
    }
    .form-item {
        margin-bottom: 0;
    }
    table,
    tr,
    td {
        border: 1px solid #d9d9d9;
    }
    table {
        width: 100%;
    }
    th {
        min-width: 128px;
    }
    td {
        min-width: 280px;
    }
    .cell-wrapper {
        min-height: 40px;
        transition: min-height ease-in-out 300ms;
        padding: 4px 16px;
        display: flex;
        align-items: center;
        > span,
        > div {
            flex: 1;
        }
    }
    .cell-wrapper-editing {
        min-height: 48px;
    }
    div[class='ant-modal-mask'] {
        background-color: #f0f2f5;
    }
    div[class='ant-modal-wrap'] {
        position: absolute;
    }
}
