@mixin Button {
    width: 90px;
    height: 30px;
    border-radius: 4px;

    font-size: 14px;
    font-family: PingFang SC;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;
}

$color-green: rgb(82, 204, 111);
$color-blue: rgb(65, 136, 242);

$button-margin: 20px;

@mixin greenButtonFilled {
    color: white;
    background-color: $color-green;
    @include Button();
}

@mixin greenButtonOutlined {
    color: $color-green;
    background-color: white;
    border: 2px solid $color-green;
    @include Button();
}

@mixin blueButtonFilled {
    color: white;
    background-color: $color-blue;
    @include Button();
}

@mixin blueButtonOutlined {
    color: $color-blue;
    background-color: white;
    border: 2px solid $color-blue;
    @include Button();
}

.assets-edit-assets-modal {
    div[class='.ant-tabs-ink-bar'] {
        background-color: #1890ff;
    }
    .total-assets {
        span {
            display: inline-block;
            font-family: PingFang SC;
            margin-right: 50px;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 34px;
        }
    }
    .cash-asset-wrapper {
        tr[class='ant-table-row ant-table-row-level-0'] {
            height: 55px;
        }
        .loan {
            margin-left: $button-margin;
            @include greenButtonFilled();
        }
        .repay {
            margin-left: $button-margin;
            @include greenButtonOutlined();
        }
    }
    .cash-asset-wrapper,
    .asset-wrapper {
        display: flex;
        flex-direction: column;

        .asset-title {
            display: flex;
            margin-top: 39px;
            margin-bottom: 21px;
        }
        .name {
            height: 29px;
            line-height: 29px;
            color: rgba(16, 16, 16, 100);
            font-size: 20px;
            text-align: left;
            font-family: SourceHanSansSC-bold;
            margin-right: 30px;
        }
        .value {
            height: 29px;
            line-height: 29px;
            color: rgba(16, 16, 16, 100);
            font-size: 20px;
            text-align: left;
            font-family: SourceHanSansSC-bold;
        }
        .operation {
            margin-left: 42px;
            display: flex;
            .in {
                @include blueButtonFilled();
            }
            .out {
                margin-left: $button-margin;
                @include blueButtonOutlined();
            }
            .buy {
                margin-left: $button-margin;
                @include greenButtonFilled();
            }
            .sell {
                margin-left: $button-margin;
                @include greenButtonOutlined();
            }
        }
        tr[class='ant-table-row ant-table-row-level-0'] {
            &:nth-child(odd) {
                background-color: rgba(0, 0, 0, 0.1);
            }
        }
    }
}
