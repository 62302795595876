.ltv-table {
    .ant-table-thead > tr > th {
        // font-size: 14px;
    }
    .ant-table.ant-table-small {
        // font-size: 16px;
    }
    .ant-table-thead {
        // height: 46px;
    }
    .ltv-table-row {
        // min-height: 46px;
    }

    .ltv-row-operation {
        .ltv-row-operation-btn {
            font-family: 'Inter';
            // font-size: 16px;
            font-weight: normal;
            line-height: 24px;
            letter-spacing: 0px;
            color: #2962ff;
            margin-right: 20px;
            cursor: pointer;
        }
    }
}
