.table-operations {
    margin-bottom: 16px;
    > button {
        margin-right: 8px;
    }
}

.editable-row-text {
    padding: 5px;
}

.editable-row-operations a {
    margin-right: 8px;
}

.ant-table-tbody {
    .ant-table-row:not(.ant-table-row-selected) {
        &:nth-child(even) {
            background: #fcfcfc;

            .ant-table-cell-fix-left {
                background: #fcfcfc;
            }
            .ant-table-cell-fix-right {
                background: #fcfcfc;
            }
        }

        &:hover {
            background: #fcfcfc;
        }
    }
    > tr:hover:not(.ant-table-expanded-row) > td {
        background-color: #eeeeee;
    }
    > .ant-table-row:hover:not(.ant-table-row-selected):nth-child(even) .ant-table-cell-fix-left {
        background-color: #eeeeee;
    }
}

.ant-table-thead th {
    word-wrap: break-word;
    background-color: red;
}
