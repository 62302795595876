.content {
    padding: 20px auto;
    padding-right: 100px;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    .item {
        width: 75%;
        display: flex;
        flex-direction: row;
        .subitem1 {
            width: 30%;
        }
        .subitem3 {
            width: 70%;
        }
    }
    .button {
        width: 25%;
        .input {
            width: calc(70% - 20px);
        }
    }
}
