.title {
    font-size: 18px;
    margin-top: 24px;
    margin-bottom: 16px;
    color: #333;
}

.overviewItem {
    background-color: white;
    border-radius: 8px;
    padding: 16px;

    .name {
        font-size: 16px;
        font-weight: bold;
        height: 26px;
        color: #333;
    }

    .time {
        font-size: 14px;
        height: 22px;
        color: #aaa;
    }

    .value {
        font-size: 16px;
        height: 26px;
        color: #aaa;
    }

    .formatValue {
        font-size: 28px;
        height: 40px;
        color: #333;
    }

    .formatUnit {
        font-size: 18px;
        height: 30px;
        color: #333;
        margin: 4px;
    }

    .currency {
        font-size: 14px;
        height: 22px;
        color: #333;
    }
}
