.ant-popover-arrow {
    opacity: 0;
}

.ant-popover-placement-top {
    padding-bottom: 0;
}

.ant-popover-placement-bottom {
    padding-top: 0;
}
// .ant-checkbox {
//     top: 0 !important;
// }
