.pannel {
    background-color: white;
    border-radius: 8px;
    padding: 16px;
    color: #333;
    height: 100%;

    .pannelTitle {
        font-size: 16px;
        line-height: 26px;
    }

    .time {
        font-size: 14px;
        line-height: 22px;
        color: #aaa;

        .icon {
            font-size: 14px;
            background-color: #f9f9fb;
            border-radius: 20px;
            padding: 3px;
            cursor: pointer;

            &.left {
                margin-right: 4px;
            }

            &.right {
                margin-left: 4px;
            }
        }
    }

    .count {
        font-size: 28px;
        line-height: 40px;
    }

    .countUnit {
        font-size: 14px;
        line-height: 22px;
        margin-left: 4px;
    }
}
