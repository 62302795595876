.assets-edit-assets-modal {
    div[class='.ant-tabs-ink-bar'] {
        background-color: #1890ff;
    }
    .total-assets {
        span {
            display: inline-block;
            font-family: PingFang SC;
            margin-right: 50px;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 34px;
        }
    }
    .cash-asset-wrapper {
        tr[class='ant-table-row ant-table-row-level-0'] {
            height: 55px;
        }
        .loan {
            width: 90px;
            height: 30px;
            border-radius: 4px;
            background-color: rgba(82, 204, 111, 100);
            color: rgba(255, 255, 255, 100);
            font-size: 14px;
            text-align: center;
            font-family: PingFang SC;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .repay {
            width: 90px;
            height: 30px;
            border-radius: 4px;
            background-color: #fff;
            color: rgba(82, 204, 111, 100);
            font-size: 14px;
            border: 2px solid rgba(82, 204, 111, 100);
            text-align: center;
            font-family: PingFang SC;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .cash-asset-wrapper,
    .asset-wrapper {
        display: flex;
        flex-direction: column;

        .asset-title {
            display: flex;
            margin-top: 39px;
            margin-bottom: 21px;
        }
        .name {
            height: 29px;
            line-height: 29px;
            color: rgba(16, 16, 16, 100);
            font-size: 20px;
            text-align: left;
            font-family: SourceHanSansSC-bold;
            margin-right: 30px;
        }
        .value {
            height: 29px;
            line-height: 29px;
            color: rgba(16, 16, 16, 100);
            font-size: 20px;
            text-align: left;
            font-family: SourceHanSansSC-bold;
        }
        .operation {
            margin-left: 42px;
            display: flex;
            .in {
                width: 90px;
                height: 30px;
                border-radius: 4px;
                background-color: rgba(65, 136, 242, 100);
                color: rgba(255, 255, 255, 100);
                font-size: 14px;
                text-align: center;
                font-family: PingFang SC;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .out {
                margin-left: 26px;
                width: 90px;
                height: 30px;
                border-radius: 4px;
                background-color: rgba(255, 255, 255, 100);
                color: rgba(65, 136, 242, 100);
                font-size: 14px;
                text-align: center;
                font-family: PingFang SC;
                border: 2px solid rgba(65, 136, 242, 100);
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        tr[class='ant-table-row ant-table-row-level-0'] {
            &:nth-child(odd) {
                background-color: rgba(0, 0, 0, 0.1);
            }
        }
    }
}
