.pannel {
    background-color: white;
    border-radius: 8px;
    padding: 16px;

    .titleArea {
        display: flex;
        justify-content: space-between;

        .name {
            font-size: 16px;
            height: 26px;
            color: #333;
        }
    }

    .time {
        font-size: 14px;
        height: 22px;
        color: #aaa;
        user-select: none;

        .icon {
            font-size: 14px;
            background-color: #f9f9fb;
            border-radius: 20px;
            padding: 3px;
            cursor: pointer;

            &.left {
                margin-right: 4px;
            }

            &.right {
                margin-left: 4px;
            }
        }
    }

    .count {
        font-size: 28px;
        line-height: 40px;
    }

    .countUnit {
        font-size: 14px;
        line-height: 22px;
        margin-left: 4px;
    }
}
