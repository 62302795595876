.title {
    font-size: 18px;
    margin-top: 24px;
    margin-bottom: 16px;
    color: #333;
}

.content {
    font-size: 14px;
    line-height: 24px;
    color: #aaa;

    .rise {
        color: #ee0000;
        margin-left: 8px;
        margin-right: 16px;
    }

    .fall {
        color: #6dae43;
        margin-left: 8px;
        margin-right: 16px;
    }
}
