.table {
    margin-top: 10px;

    .tableTitle {
        text-align: center;
        border-top: 1px solid #aaa;
        padding: 6px;
        background: black;
        color: white;
    }

    .detail {
        display: table;
        width: 100%;

        .row {
            display: table-row;

            .left {
                display: table-cell;
                width: 50%;
                border-top: 1px solid #aaa;
                background-color: black;
                color: white;
                padding: 6px;
            }

            .right {
                display: table-cell;
                width: 50%;
                border-top: 1px solid #aaa;
                padding: 6px;
            }
        }
    }
}
