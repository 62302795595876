.pannel {
    background-color: white;
    border-radius: 8px;
    padding: 16px;
    color: #333;
    height: 100%;

    .pannelTitle {
        font-size: 16px;
        line-height: 26px;
    }

    .time {
        font-size: 14px;
        line-height: 22px;
        color: #aaa;
    }

    .count {
        font-size: 28px;
        line-height: 40px;
    }

    .countUnit {
        font-size: 14px;
        line-height: 22px;
        margin-left: 4px;
    }
}
