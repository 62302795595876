@import '~antd/es/style/themes/default.less';
@import '~antd/dist/antd.less';
@import './style/variables_saas.less';
@import './style/theme.less';

body {
    background-color: #f0f2f5 !important;
    font-size: 14px;
    color: @lk-text-color;
}

h1 {
    font-size: 16px;
    color: @lk-text-color;
    font-weight: bold;
    margin-bottom: 0;
}

.app_menu_switch {
    z-index: 10001;
}

.Index_Navigator {
    height: 64px;
    z-index: 10000;
}

section.app_layout {
    overflow: auto;
}

main.app_layout_content {
    position: relative;

    @media (max-width: 1000px) {
        .ant-btn {
            padding: 4px 8px;
            font-size: 12px;
        }

        .ant-btn + .ant-btn {
            margin-left: 6px;
        }
    }

    @media (min-width: 1000px) {
        .btn-finish {
            align-self: flex-end;
        }
    }

    .ant-pagination-item-active a {
        color: #121518;
    }

    .ant-pagination-item-active {
        background-color: #ffc612;
    }
}

.ant-layout .ant-layout-header {
    background-color: white;
}

.ant-divider-horizontal {
    margin: 12px 0;
}

.ant-modal-root {
    .modal-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        &::before {
            height: 0;
        }
    }

    .ant-modal-centered {
        display: flex;
        justify-content: center;
        align-items: center;

        &::before {
            height: 0;
        }
    }
}

.ant-layout-sider-children .MenuContainer {
    background-color: white;
}

.bg--primary {
}

aside.ant-layout-sider {
    background: white;
}

.MenuContainer .MenuContainer__Wrapper {
    border-right: 0px !important;
}

.ant-layout-sider .ant-layout-sider-trigger {
    background: #f0f2f5;
    color: #333;
    width: 40px !important;
    height: 40px !important;
    margin-bottom: 8px;
    margin-left: 152px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}

.ant-layout-sider-collapsed .ant-layout-sider-trigger {
    margin-left: 10px;
}

.ant-layout-sider-collapsed {
    max-width: 60px !important;
    min-width: 60px !important;
}
.lk_link {
    color: @lk-text-color;
    &:hover {
        color: @lk-text-color;
    }
    cursor: pointer;
    text-decoration: underline;
}
.MenuContainer .ant-menu-root.ant-menu-inline,
.ant-menu-root.ant-menu-vertical {
    color: black;
}
// sass
.ant-modal {
    max-width: calc(100vw);
}

.ltv-custom-modal {
    .ant-modal-content {
        .ltv-form {
            .ant-row {
                height: 40px;
                margin-bottom: 16px;
                label {
                    font-family: '苹方-简';
                    font-weight: 500;
                    color: #999999;
                }
                .ant-form-item-control-input-content {
                    width: 213px;
                    .ltv-item {
                        font-family: '苹方-简';
                        font-size: 14px;
                        font-weight: 500;
                        color: #3d3d3d;
                    }
                }
                .ltv-form-select {
                    .ant-select-selector {
                        border-radius: 8px;
                    }
                }
            }
            .ltv-history-table-container {
                .ltv-history-table {
                    box-sizing: border-box;
                    border-width: NaNpx NaNpx 1px NaNpx;
                    border-style: solid;
                    border-color: #dddddd;
                    .ant-table-thead > tr > th {
                        // font-size: 14px;
                        font-family: '苹方-简';
                        font-weight: 500;
                        color: #999999;
                    }

                    .ant-table.ant-table-small {
                        font-family: PingFang SC;
                        // font-size: 16px;
                    }

                    .ant-table.ant-table-small .ant-table-tbody > tr > td {
                        border: none;
                    }

                    .ant-table-thead {
                        height: 40px;
                    }

                    .ltv-history-table-row {
                        min-height: 40px;
                        background-color: #ffffff;
                    }
                    .ant-table-tbody .ant-table-row:not(.ant-table-row-selected):nth-child(even) {
                        background-color: #ffffff;
                    }
                }
            }
        }
    }
}
.ltv-custom-modal.ltv-custom-hide-footer {
    .ant-modal-content {
        .ant-modal-footer {
            display: none;
        }
    }
}
