.bottom {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
:global {
    .base-form .ant-form-item {
        margin-bottom: 20px;
    }
    .ant-form .ant-form-item {
        margin-bottom: 10px;
        .ant-form-item-label {
            // max-width: 30%;
        }
        .ant-form-item-control {
            // max-width: 70%;
        }
        .custom-item .ant-form-item-control {
            max-width: 100%;
        }
    }
}
